<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-body">
        <p v-if="loading">
          <img
            :src="require('@/assets/images/loading.svg')"
            style="width: 40px; height: 40px"
            alt=""
          />
          جاري التحميل...
        </p>
        <div class="row">
          <div class="col-12 col-lg-4" v-for="worker in workers" :key="worker.id">
            <div class="card">
              <div class="card-body">
                Woker #{{ worker.id }}
              </div>
              <div class="card-footer">
                <ul>
                  <li>
                    الكل: {{ worker.total }}
                  </li>
                  <li>
                    متصل: {{ worker.status_1 }}
                  </li>
                  <li>
                    لم يتصل بعد: {{ worker.status_0 }}
                  </li>
                  <li>
                    قطع الاتصال: {{ worker.status_2 }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 table-responsive" v-if="!loading">
          <table class="table table-hover table-bordered">
            <thead>
              <th>المستخدم</th>
              <th>الجهاز</th>
              <th>الحالة</th>
              <th>معلومات الجهاز (تلقائياً)</th>
              <th>تاريخ الاضافة</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="device in devices" :key="device._id">
                <td>
                  <a
                    class="text-success"
                    @click="$router.push('/users#' + users[device.user_id]._id)"
                  >
                    {{ users[device.user_id].name }}
                    <br />
                    {{ users[device.user_id].phone }}
                  </a>
                </td>
                <td>
                  <span>
                    <img
                      v-if="device.info"
                      style="width: 50px; height: 50px; border-radius: 50%"
                      :src="device.info.image"
                      @error="
                        device.info.image =
                          'https://cdn-static.brmja.com/storage/uploads/images/63871906bdfcc560647582.webp'
                      "
                      alt=""
                    />
                    {{ device.title }}
                  </span>
                </td>
                <td>
                  <span class="badge bg-warning" v-if="device.status == 0">
                    <i class="fa fa-clock-o"></i>
                    لم يتصل بعد
                  </span>
                  <span class="badge bg-success" v-if="device.status == 1">
                    <i class="fa fa-check"></i>
                    متصل الآن
                  </span>
                  <span class="badge bg-danger" v-if="device.status == 2">
                    <i class="fa fa-times"></i>
                    قُطع الاتصال
                  </span>
                </td>
                <td>
                  {{ device.phone }}
                  <br />
                  <span v-if="device.info"> {{ device.info.platform }} </span
                  ><br />

                  <span class="btn btn-sm"
                    ><i class="fa fa-bug"></i> {{ device.worker }}</span
                  >
                  <br />
                </td>
                <td>
                  {{ device.add_date.split("T")[0] }}
                </td>
                <td>
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="خيارات"
                    variant="primary"
                    size="sm"
                  >
                    <b-dropdown-item
                      @click="restart(device._id)"
                      v-if="device.status == 1"
                    >
                      <i class="fa fa-refresh"></i>
                      اعادة تشغيل
                    </b-dropdown-item>
                    <b-dropdown-item @click="kick(device._id)">
                      <i class="fa fa-ban"></i>
                      تعطيل/طرد
                    </b-dropdown-item>
                    <b-dropdown-item @click="idd(device._id)">
                      <i class="fa fa-key"></i>
                      device id
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteDevice(device._id)">
                      <i class="fa fa-trash"></i>
                      حذف
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      current: {},
      devices: [],
      users: {},
      workers: [],
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/admin/users/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      r.response.forEach((element) => {
        g.users[element._id] = element;
      });
      g.getDevices();
    });
  },
  methods: {
    getDevices() {
      var g = this;
      g.loading = true;
      $.post(api + "/admin/devices/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.loading = false;
            var arr = [];
            r.response.forEach(function (a) {
              var found = false;
              g.workers.forEach(function(w){
                if(w.id == a.worker){
                  found = true
                }
              })
              if(!found){
                g.workers.push({
                  id: a.worker,
                  total: 0,
                  status_0: 0,
                  status_1: 0,
                  status_2: 0
                })
              }
              var workers = []
              g.workers.forEach(function(w){
                if(w.id == a.worker){
                  w.total = w.total + 1
                  if(!w["status_" + a.status]){
                    w["status_" + a.status] = 0
                  }
                  w["status_" + a.status] = w["status_" + a.status] + 1
                }
                workers.push(w)
              })
              g.workers = workers
              if (a.user_id == window.location.hash.split("#")[1] || !window.location.hash) {
                arr.push(a);
              }
            });
            g.devices = arr;
        })
        .catch(function () {
          alert("error server or internet");
        });
    },
    deleteDevice(id) {
      var g = this;
      if (confirm("متأكد من حذف الجهاز؟")) {
        $.post(api + "/admin/devices/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.getDevices();
          })
          .catch(function () {
            alert("error server or internet");
          });
      }
    },
    idd(id){
      prompt('id:', id)
    },
    restart(id) {
      var g = this;
      if (confirm("متأكد من اعادة تشغيل الخدمة؟")) {
        $.post(api + "/admin/devices/restart", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style>
</style>